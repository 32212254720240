<!--  -->
<template>
  <div style="  height:100vh;
    width:100vw;
    background:rgba(64, 192, 249, 0.05)">

    <van-sticky >
     <!-- 盘点类型 -->
     <div class="point_top">
        <i class="el-icon-arrow-left" @click="back()"></i>
        <!-- <div class="point_kind">{{ pointTitle.inventoryTypeName }}</div> -->
        <div class="point_kind">采价</div>
        <div class="point_point">…</div>
      </div>
    </van-sticky>
     
    <div>
        <div style="width:96%;height:22vw;background:white;border-radius: 2vw;margin-left:2%;margin-top: 2vw;display: flex;align-items: center;" @click="turnPrice()">
             <div style="margin-left:5vw;color: #000000;">
              <div style="margin-left: 3.5vw;font-size:6vw;font-weight:600;">新增采价 </div> 
              <div style="color:#6E6E6E;transform: scale(0.7);">New purchase </div>
              <div style="color:#6E6E6E;transform: scale(0.7);">price</div>
             </div>  
             <div style="margin-left:40vw"><img src="../assets/icon//caigou.png" alt="" style="width:16vw;height:18vw"></div>
        </div>
        <div style="width:96%;height:22vw;background:white;border-radius: 2vw;margin-left:2%;margin-top: 2vw;display: flex;align-items: center;" @click="turntable()">
               <div style="margin-left:5vw">
                <div style="font-weight:600;margin-left: 3.5vw;font-size:6vw;">报表查看</div>
               <div style="color:#6E6E6E;transform: scale(0.7);">Headquarters</div>
               <div style="color:#6E6E6E;transform: scale(0.7);">moving disc</div>
               </div>
               <div style="margin-left:40vw">
                <img src="../assets/icon/baobiaochakan.png" alt="" style="width:16vw;height:18vw">
               </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },

  components: {},

  computed: {},

  methods: {
   
    turnPrice( ){
        // this.$router.push("./priceCollect_add")
        this.$router.push("./priceCollect_add")
    },
    turntable(){
        this.$router.push("./priceCollect_table")
    },
    // 返回
    back() {
      //this.$router.go(-1);
      this.$router.push('/point');
    },

  }
}

</script>
<style lang='less' scoped>
</style>
<style>
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  font-size: 4.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight:600;
}
</style>